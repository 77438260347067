@import "mixins/before";
@import "variables/index";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "mixins/after";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../node_modules/ladda/css/ladda.scss";
@import "../../node_modules/react-select/scss/default.scss";

@import "overrides/index";

@import "components/index";

@media print {
  /* All your print styles go here */
  header,
  footer,
  nav {
    display: none !important;
  }
}
